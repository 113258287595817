document.querySelector('.js-mobile-open').onclick = () => {
  document.querySelector('.js-main-nav').classList.toggle('active');
};

document.querySelector('.js-mobile-close').onclick = () => {
  document.querySelector('.js-main-nav').classList.toggle('active');
};

const navItems = document.querySelectorAll('.js-main-nav-item');

navItems.forEach(
  (item) =>
    (item.onclick = () => {
      document.querySelector('.js-main-nav').classList.remove('active');
    })
);
